import { BaseComponent } from "../../../common/base-component.js";
//import PubSub from "../../../common/pubsub.js";

import { DT_M_BREAK, belowBreak, aboveBreak } from "../../../common/helpers.js";
import missionController from "./mission_controller.js";

const style = `
    :host {
        position: relative;
        display: block;
    }
    .mission {
        border: 1px solid var(--color-border);
        min-height: 4.8125rem;
        display: flex;
        flex-direction: column;
        margin: .75rem 0;
    }
    .mission-top {
        display: flex;
        flex-direction: row;
        margin: 0.5rem clamp(0.75rem, 0.2500rem + 2.5000vw, 0.5rem);
        gap: 0.5rem 0;
        justify-content: stretch;
    }
    .mission-title {
        display: flex;
        flex-direction: column;
        width: 7.125rem;
        border-right: .0625rem solid var(--color-border);
    }
    .title-two {
        text-transform: capitalize;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.4;
        letter-spacing: 0.045rem;
        color: var(--text-gray-dark);
    }
    .title-one {
        text-transform: capitalize;
        color: var(--text-gray-dark);
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 1.4;
        letter-spacing: 0.03375px;
    }
    ::slotted([slot="info-icon"]) {
        padding: 0 .5rem;
    }
    ::slotted([slot="label"]) {
        top: 0.75rem;
        right: 0.75rem;
        position: absolute;
    }
    ::slotted([slot="dropdown-toggle"]) {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
    }
    ::slotted([slot="progress-bar"]) {
        align-self: center;
    }
    @media (max-width: ${DT_M_BREAK}) {
        .mission-top {
            flex-wrap: wrap;
            gap: 2.5rem 0;
        }
        .mission-title {
            order: 1;
            padding-right: 0.5rem;
        }
        ::slotted([slot="label"]) {
            top: 0;
            right: 0;
            position: absolute;
        }
        ::slotted([slot="info-icon"]) {
            order: 2;
            margin-right: 0.5rem;
        }
        ::slotted([slot="timer"]) {
            order: 3;
        }
        ::slotted([slot="progress-bar"]) {
            order: 4;
        }
        ::slotted([slot="dropdown-toggle"]) {
            order: 5;
            z-index: 1;
        }
    }
`


customElements.define(
    "mission-single",
    class extends BaseComponent {
        static observedAttributes = ['data-title-line-one', 'data-title-line-two'];

        constructor() {
            super(style);
            missionController.subscribe('progress-increment', ({alias}) => {
                if(alias === this.dataset.alias) {
                    if(aboveBreak()) this.scrollIntoView({behavior:'instant', block: 'start'});
                }
            })
            missionController.subscribe('progress-complete', ({alias}) => {
                if(alias === this.dataset.alias) {
                    if(belowBreak()) this.scrollIntoView({behavior:'instant', block: 'start'});
                }
            });
        }

        render() {
            return `
                <div class="mission">
                    <div class="mission-top">
                        <slot name="mission-thumb"></slot>
                        <slot name="progress-bar"></slot>
                        <slot name="timer"></slot>
                        <slot name="dropdown-toggle"></slot>
                    </div>
                    <slot name="dropdown"></slot>
                    <slot name="label"></slot>
                </div>
            `
        }
    },
);
